
























import { defineComponent, useRoute } from '@nuxtjs/composition-api'

import useCatalogModal from '~/utils/compositions/useCatalogModal'

// Todo: refactor axios get groups and set to store with cache
export default defineComponent({
  setup() {
    const { isOpen: showCatalogModal } = useCatalogModal()
    const route = useRoute()

    return {
      showCatalogModal,
      route,
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    i18nHead.htmlAttrs = { lang: this.$i18n.locale }

    // remove canonical on home page
    const notCanonical = ['/ua/', '/']
    if (notCanonical.includes(this.route.fullPath)) {
      i18nHead.link = i18nHead.link.filter((link) => link.rel !== 'canonical')
    }

    // skip hreflang: 'uk' | hreflang: 'ru'
    const notDefaultAlt = ['uk', 'ru', 'x-default']
    i18nHead.link = i18nHead.link.filter((link) => {
      if (link.rel === 'alternate') {
        if (notDefaultAlt.includes(link.hreflang)) {
          return false
        }
      }
      return true
    })

    return { ...i18nHead }
  },
})
